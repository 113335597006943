// Here you can add other styles
.loading-pos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .bg-justice {
    background-color: #ea7324 !important;
  }
  
  .btn-justice {
      color: #fff;
      background-color: #ea7324;
      border-color: #ea7324;
  } */

  .custom-button {
    //background-color: #1e2455;
    background-color:#009739;
    color: white !important; /* Force la couleur du texte en blanc */
    /* Ajoutez d'autres styles personnalisés au besoin */

    &:hover {
      background-color: #783c00; /* Marron lors du survol */
      color: white !important; /* Texte en blanc lors du survol */
      
      /* Ajoutez d'autres styles au survol au besoin */
    }
  }

.c-sidebar {
  color: #fff;
  background: #783c00;
}

.c-footer {
  color: #fff;

  height: max-content;
}

.justice-logo {
  background-image: url('../assets/logo-mjdhpc.jpeg');
}

.login-background-image {
  //background-color: #ea7324;
  background-image: url('../assets/certificat_bg.jpg');
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.custom-image-size {
  width: 29vh;
  height: 29vh;
}

#login-page {
  top: 50%;
  transform: translateY(50%);
}


.text-justice-decision-parent {
  font-size: 1.10rem;
  color: #783c00;
}

.custom-decision-checked {
  background-color: #7d6041a0 !important;
  color: #fff !important;
}

/* Error highlight on input fields */
.av-valid[required],
.av-valid.required {
  border-left: 5px solid green;
}

.av-invalid:not(form) {
  border-left: 5px solid red;
}

/*label:has(+ div)::after {
  content: " *";
  color: red !important;
}*/

/*label:has(+ div[required])::after {
  content: " *";
  color: red !important;
}*/

label[required]::after {
  content: " *";
  color: red;
}




.select-valid[required],
.select-valid.required {
  border-left: 5px solid green;
}

.select-valid {
  border-left: 5px solid green;
}

.select-invalid:not(form) {
  border-left: 5px solid red;
}

.span-error {
  color: red !important;
  font-size: 0.7rem;
}

.select-disable {
  background-color: rgb(27, 6, 6) !important;
}

.menu-container {
  text-align: center;
  margin: 0 auto; /* Pour centrer horizontalement la div parente */
}
